import {getFilters, search} from "@/api/requests";

export default {
    name: "Search",
    components: {
        BaseSection: () => import("@/components/base/Section"),
        BaseBody: () => import("@/components/base/Body"),
        BaseCoursePreview: () => import("@/components/base/CoursePreview"),
    },

    data() {
        return {
            classes: [],
            filters: null,
            searchQuery: "",
            searchLabel: this.$i18n.t("Search"),
            levelsLabel: this.$i18n.t("Levels"),
            teachersLabel: this.$i18n.t("Teachers"),
            goalsLabel: this.$i18n.t("Goals"),
            stylesLabel: this.$i18n.t("Styles"),
            durationsLabel: this.$i18n.t("Duration"),
            loading: false,
            selectedDurations: [],
            selectedLevels: [],
            selectedTeachers: [],
            selectedStyles: [],
            selectedGoals: [],
        };
    },
    mounted() {
        this.loadFilters()
        this.search()
    },
    methods: {
        async search() {
            this.loading = true
            this.classes = []
            console.log(this.selectedDurations)
            let filterQuery = ""
            if (this.selectedLevels.length === 1) {
                filterQuery += `(id_level=${this.selectedLevels[0]})`
            } else if (this.selectedLevels.length > 1) {
                filterQuery += `(id_level=${this.selectedLevels.join(" OR id_level=")})`
            }
            let and = ""
            if (filterQuery !== "") {
                and = " AND "
            }
            if (this.selectedStyles.length === 1) {
                filterQuery += `${and}(id_style=${this.selectedStyles[0]})`
            } else if (this.selectedStyles.length > 1) {
                filterQuery += `${and}(id_style=${this.selectedStyles.join(" OR id_style=")})`
            }
            if (filterQuery !== "") {
                and = " AND "
            }
            if (this.selectedTeachers.length === 1) {
                filterQuery += `${and}(id_teacher=${this.selectedTeachers[0]})`
            } else if (this.selectedTeachers.length > 1) {
                filterQuery += `${and}(id_teacher=${this.selectedTeachers.join(" OR id_teacher=")})`
            }
            if (filterQuery !== "") {
                and = " AND "
            }
            let durationFilters = []
            const minDuration = 1
            const maxDuration = 300
            const minDurationForSearch = {10: minDuration, 15: 12, 30: 23, 45: 37, 60: 50}
            const maxDurationForSearch = {10: 12, 15: 23, 30: 37, 45: 50, 60: maxDuration}
            for (const duration of this.selectedDurations) {
                let durationFilter = ""
                const selectedMinDuration = minDurationForSearch[duration]
                const selectedMaxDuration = maxDurationForSearch[duration]
                const minDurationInSec = selectedMinDuration * 60
                const maxDurationInSec = selectedMaxDuration * 60
                if ((minDuration !== selectedMinDuration) && (maxDuration !== selectedMaxDuration)) {
                    durationFilter = `(duration >= ${minDurationInSec} AND duration <= ${maxDurationInSec})`
                } else if (maxDuration !== selectedMaxDuration) {
                    durationFilter = `(duration <= ${maxDurationInSec})`
                } else {
                    durationFilter = `(duration >= ${minDurationInSec})`
                }
                durationFilters.push(durationFilter)
            }
            if (durationFilters.length > 0) {
                filterQuery += `${and}(${durationFilters.join(" OR ")})`
            }
            let sortItems = []
            for (const goalColumnName of this.selectedGoals) {
                sortItems.push(`${goalColumnName} desc`)
            }
            let sortQuery = "id desc"
            if (sortItems.length > 0) {
                sortQuery = sortItems.join(", ") + ", id desc"
            }
            const res = await search(this.searchQuery, filterQuery, sortQuery)
            this.classes = res.classes
            this.loading = false
        },
        async loadFilters() {
            let filters = await getFilters()
            filters.levels = filters.levels.map((level) => {
                return {
                    ...level,
                    name: this.$i18n.t(level.name),
                }
            })
            filters.teachers = filters.teachers.sort((a, b) => a.id > b.id)
            filters.styles = filters.styles.sort((a, b) => a.id > b.id)
            filters.durations = filters.durations.map((duration) => {
                return {
                    id: duration,
                    name: `${duration} ${this.$i18n.t("min")}`
                }
            })
            filters.goals = filters.goals.map((goal) => {
                return {
                    ...goal,
                    columnName: goal.name,
                    name: this.$i18n.t(goal.name),
                }
            })
            this.filters = filters
        }
    }
};
